import React from "react"
import Blob from "./../ServiceLanding/Hero/Blob"
import { Link } from 'gatsby'
// OUR WORK HERO

function Hero({ props }) {
    const { title, description, sub } = props

    return (
        <section className="relative isolate grid h-[calc(100vh-75px)] items-center overflow-x-clip lg:h-[calc(100vh-104px)]">
            <div className="absolute top-1/2 left-1/2 z-[-1] w-full -translate-x-1/2 -translate-y-1/2 lg:left-0 lg:top-[-20%] lg:max-w-7xl lg:translate-y-0 lg:-translate-x-1/3">
                <Blob />
            </div>

            <div className="container mx-auto">
                <div className="border-box mx-auto grid grid-rows-4">
                    <div className="row-span-3 grid grid-cols-1 place-items-center md:grid-cols-12">
                        <div className="place mt-[95px] max-w-[600px] text-center md:col-span-5 md:col-start-1 md:mt-[0] md:w-full md:text-left">
                            <h1 className="page-title mb-4 text-6xl font-bold">
                                {title}
                                <br />
                            </h1>
                        </div>
                        <div className="z-[2] mt-[49px] md:col-span-4 md:col-start-8 md:mt-[0]">
                            <p className="mx-auto w-5/6 text-center text-sm tracking-[-0.025rem] text-[#1D2333]/[0.8] sm:text-left md:w-full md:text-lg md:leading-9">
                                {description && (
                                    <p className="hero-text mb-0 text-[#1D2333]">
                                        {description}
                                    </p>
                                )}
                                {sub && (
                                    <p className="hero-text hero-text--sub font-bold text-[#1D2333]">
                                        {sub}
                                    </p>
                                )}
                            </p>
                        </div>
                    </div>
                    <div className="flex lg:flex-row flex-col items-center mt-6 gap-8 flex-wrap">
                        <a class="mx-auto max-w-max md:mx-0" target="_blank" href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ16YM_W-6RTPHNhNimw5PLy0hA-VsGMKuB8bvBLsmEb9Ez9HEppAKiGxU5EFrr8naaIXVYa4AO0">
                            <button type="button" onClick={() => {
                                window.dataLayer.push({
                                    event: "kieran_consultation",
                                    eventCategory: "consultation"
                                })
                            }}
                                class="button group inline-flex items-center gap-x-2 gray-btn ">Book a Consultation</button>
                        </a>
                        <Link to="/contact">
                            <div 
                                onClick={() => {
                                    window.dataLayer.push({
                                        event: "contactbutton_click",
                                        eventCategory: "CTA"
                                    })
                                }}
                                role="button" 
                                className="uppercase duration-300 text-dark-gunmetal btn-pulse  hover:!gap-x-4 !tracking-normal flex items-center !gap-1.5 !text-base w-fit border-b border-dark-gunmetal"
                            >
                                contact us
                                <svg className="size-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/>
                                </svg>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero
